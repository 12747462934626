import { createContext, ReactNode, useContext } from "react";
import React from "react";
import { useMatchMedia } from "@/hooks";

const LocalStateContext = createContext({
  isTablet: false,
  isDesktop: false,
  isLargeDesktop: false,
  isXLargeDesktop: false,
});
const LocalStateProvider = LocalStateContext.Provider;

interface Props {
  children: ReactNode;
}

function MatchMediaProvider({ children }: Props) {
  const isTablet = useMatchMedia("(min-width: 1024px)");
  const isDesktop = useMatchMedia("(min-width: 1280px)");
  const isLargeDesktop = useMatchMedia("(min-width: 1536px)");
  const isXLargeDesktop = useMatchMedia("(min-width: 1920px)");

  return (
    <LocalStateProvider
      value={{ isTablet, isDesktop, isLargeDesktop, isXLargeDesktop }}
    >
      {children}
    </LocalStateProvider>
  );
}

function useMatchMediaContext() {
  const all = useContext(LocalStateContext);
  return all;
}

export { MatchMediaProvider, useMatchMediaContext };
