import React, { ComponentProps, FC, PropsWithChildren } from "react";

export const combineComponents = (
  ...components: FC<PropsWithChildren<unknown>>[]
): FC<PropsWithChildren<unknown>> =>
  components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({
        children,
      }: ComponentProps<FC<PropsWithChildren<unknown>>>): JSX.Element =>
        (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        ),
    ({ children }) => <>{children}</>
  );
